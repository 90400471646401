/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import { Column, FlexRow, Row } from '../components/defaults';
import styled from 'styled-components';
import folateBg from '../images/background-folate.svg';
import { useSelector } from 'react-redux';
import pdf from '../images/pdf.svg';
import api from '../utils/api';
import folateAd from '../images/folate-ad.png';
import genetics from '../images/genetics.svg';
import { FolateBrain } from '../images/folate-brain';
import { FolateUpperGut } from '../images/folate-uppergut';
import { FolateLowerGut } from '../images/folate-lowergut';
import { FolateMindBrain } from '../images/folate-mindbrain';
import { TestStatus } from './test-status';
import { BannerButton } from './banner-button';
import { LoadingMask } from './loading-mask';
import { downloadPDF } from '../utils/downloader';

const affectsCopy = {
    'COMT': {
        text: 'MIND',
        icon: FolateBrain
    },
    'AHCY': {
        text: 'MIND',
        icon: FolateBrain,
    },
    'MTRR': {
        text: 'UPPER GUT',
        icon: FolateUpperGut
    },
    'MTR': {
        text: 'LOWER GUT',
        icon: FolateLowerGut
    },
    'MTHFR': {
        text: 'MIND & GUT',
        icon: FolateMindBrain
    }
}

const WelcomeBox = styled(Column)`
    position: relative;
    border-radius: 32px;
    overflow: hidden;

    @media (max-width: 762px) {
        border-radius: 15px;
    }
`;


const FolateBox = styled(WelcomeBox)`
    background: #4F59F0;
    position: relative;
    grid-area: folate;
`;

const FolateBg = styled.img`  
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
`;

const Icon = styled.img`
    height: 38px;
    width: 38px;
    margin-bottom: 32px;
`;

const FolateText = styled.div`
    font-family: 'Satoshi-Medium';
    color: white;
    font-size: 28px;
    margin-bottom: 3px;
    width: 50%;

    span {
        background: -webkit-linear-gradient(102.69deg, #F9BF29 3.41%, #FFF6DF 98.2%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    @media (max-width: 762px) {
        font-size: 22px;  
        width: 90%;
        margin-bottom: 20px;
    }

    ${props => props.gold ? `
        background: -webkit-linear-gradient(102.69deg, #F9BF29 3.41%, #FFF6DF 98.2%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    `: ''}
`;

const Content = styled(Row)`
    @media (max-width:900px) {
        flex-direction: column;
    };

    z-index: 2;
`;

const TextContent = styled(Column)`
    flex: 1;
    padding: 67px 70px;

    @media (max-width: 768px) {
        padding: 30px 30px;
    }
`;

const FlexTextContent = styled(FlexRow)`
    flex: 1;
    z-index: 3;
`;

const FolateGrid = styled.div`
    display: flex;
    gap: 37px;
    margin-top: 37px;
    margin-bottom: 42px;
    flex-wrap: wrap;

    @media (max-width: 768px) {
        gap: 20px;
    }
`;

const FolateVariantWrapper = styled(Column)`
    color: white;
    font-size: 18px;
    flex: 1;
    min-width: 220px;
    font-family: 'Satoshi-Medium';
    background: ${props => props.color};
    color: #051F73;
    padding: 15px 23px;
    border-radius: 16px;
    box-sizing: border-box;
`;

const FolateTitle = styled.div`
    margin-bottom: 15px;
    font-family: 'Satoshi-Bold';
    font-size: 24px;
`;

const FolateSubtitle = styled.div`
    font-size: 18px;
    white-space: nowrap;
`;  

const FolateVariant = ({name = 'COMT'}) => {
    const color = {
        'heterozygous': '#FFCE1E',
        'homozygous_normal': '#40F93C',
        'homozygous_risk': '#FF374F'
    }
    const geneticData = useSelector((state) => state.user?.userData?.genetic_kit?.data);
    const variantValue = geneticData[name];
    const Icon = affectsCopy[name].icon
    
    return <FolateVariantWrapper color={color[variantValue]}>
        <FolateTitle>{name}</FolateTitle>
        <Row>
            <Column style={{ marginRight: 20, flex: 1  }}>
                <FolateSubtitle>Affects</FolateSubtitle>
                <FolateSubtitle><i>{affectsCopy[name].text}</i></FolateSubtitle>
            </Column>
            <Icon style={{height: 56 }} color={color[variantValue]} />
        </Row>
    </FolateVariantWrapper>
}

const FolateButton = styled(Row)`

    pointer-events: ${props => props.disabled ? 'none' : 'initial'};
    cursor: ${ props => props.disabled ? 'none' : 'pointer'}    ;
    white-space: nowrap;
    margin-top: 20px;
    padding: 16px;
    padding-left: 30px;
    padding-right: 30px;
    height: 78px;
    box-sizing: border-box;
    color: white;
    border-radius: 100px;
    background: rgba(0, 0, 0, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(20px);
    font-size: 20px;
    align-items: center;

    @media (max-width: 762px) {
        font-size: 16px;
        height: 68px;
    }
`;

const AdImage = styled.img`
    height: 100%;
    object-fit: cover;
    position: absolute;
    right: 0px;
    top: 0px;

    @media (max-width: 762px) {
        position: initial;
    }
`;



const FolateWaiting = ({ status }) => {

    return <FlexTextContent>
        <Column style={{ flex: 2, padding: 40, zIndex: 2 }}>
            <Icon src={genetics} />
            <FolateText>Get to know the strengths and weaknesses of your body on a genetic level.</FolateText>
            <div style={{flex: 1}} />
            {!!status && <FolateButton disabled={true} style={{ width: 'min-content'}}>
                Your Results will be ready soon!
            </FolateButton>}
            {!status && <BannerButton 
            style={{ marginTop: 10, alignSelf: 'flex-end' }}
            onClick={() => window.open('https://trumelabs.com/folate-genetic-test/','_blank')}
            outerText='Start your rejuvenation journey'
            innerText='Order Test'
            />}
        </Column>
        <AdImage src={folateAd} />
    </FlexTextContent>

}

export const Folate = () => {

    const [loading, setLoading] = useState(false);
    const [testStatusVisible, setTestStatusVisible] = useState(false);
    const geneticKit = useSelector((state) => state.user?.userData?.genetic_kit);


    const openPdf = async () => {
        if (!geneticKit?.data) return setTestStatusVisible(true);
        setLoading(true)
        try {
            const res = await api.get(`/reports?barcode=${geneticKit.barcode}`);
            await downloadPDF(res.data.url);
        } catch (err) {
            
        }
        setLoading(false);
    } 


    return <FolateBox>
        {loading && <LoadingMask />}
        {geneticKit?.data ? <Content >
            <TestStatus visible={testStatusVisible} setVisible={setTestStatusVisible} />
            <TextContent>
                <FlexRow breakpoint={'1000px'} >
                    <Column style={{ flex: 1, marginBottom: 30}}> 
                        <FolateText>Your folate genotype has
                         <span> 10 variations that impact </span> 
                        efficient folate metabolism.</FolateText>
                    </Column>
                    
                <BannerButton 
                    outerText='View your full genetics report!'
                    innerTextMobile='Download Report'
                    disabled={loading}
                    onClick={() => openPdf(geneticKit.barcode)}
                    innerText={''}
                    icon={pdf}
                />
                </FlexRow>
                <FolateGrid>
                    <FolateVariant name='COMT'/>
                    <FolateVariant name='AHCY'/>
                    <FolateVariant name='MTRR'/>
                    <FolateVariant name='MTR'/>
                    <FolateVariant name='MTHFR'/>
                </FolateGrid>
            </TextContent>
        </Content> : <FolateWaiting status={geneticKit?.status === 'IN_LAB' ? 'IN_LAB' : ''} />}
        <FolateBg src={folateBg} /> 
    </FolateBox> 
}


const LoadingIcon = styled.img`
    height: 40px;
`;

